<template>
  <img
      :data-src="src"
      :data-srcset="srcset"
      :alt="alt"
      :width="width"
      class="AppImage"
  >
</template>

<script>
import lozad from 'lozad';

export default {
  name: 'lazyimage',
  props: {
    backgroundColor: {
      type: String,
      default: '#efefef',
    },
    height: {
      default: null,
    },
    src: {
      type: String,
      default: null,
      required: true
    },
    alt: {
      type: String,
      default: "image"
    },
    srcset: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    // As soon as the <lazy-image> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    const setLoadingState = () => {
      this.loading = false;
    };
    this.$el.addEventListener('load', setLoadingState);
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      this.$el.removeEventListener('load', setLoadingState);
    });

    // We initialize Lozad.js on the root
    // element of our component.
    const observer = lozad(this.$el);
    observer.observe();
  },
};
</script>

<style lang="scss">
// Responsive image styles.
.AppImage {
  max-width: 100%;
  max-height: 100%;
  //width: auto;
  //height: auto;
  vertical-align: middle;
}
</style>